<template>
    <div class="card bs-5 mb-5">
        <div class="fl-te-c pb-3">
            <h4 v-html="'CONTACT MESSAGE'"></h4>

            <div>
                <btn text="Backup" icon="fa fa-download" :loading="downloading"
                     loading-text="Downloading.." @click="downloadBackup" />
            </div>
        </div>
        <vue-table :fields="fields" :url="listUrl" :per-page="10" ref="table">
            <template #recive_newsletters="{rowData}">
                <div v-if="rowData.recive_newsletters">Yes</div>
                <div v-else>No</div>
            </template>
<!--            <template #services="{rowData}">-->
<!--                <div v-if="rowData.services">-->
<!--                    <div v-for="(i, k) in rowData.services.split(',')" v-bind:key="k">-->
<!--                        {{i}}-->
<!--                    </div>-->
<!--                </div>-->
<!--            </template>-->
        </vue-table>
    </div>
</template>

<script>
import urls from '../../../../data/urls';
import axios from 'secure-axios';

export default {
    name : 'ContactMessage',
    data () {
        return {
            downloading : false,
            listUrl     : urls.admin.report.contactUS,
            fields      : [
                {
                    name       : 'first_name',
                    sortField  : 'first_name',
                    title      : 'First Name',
                    titleClass : ''
                },
                {
                    name       : 'email',
                    sortField  : 'email',
                    title      : 'Email',
                    titleClass : ''
                },
                {
                    name       : 'phone_number',
                    sortField  : 'phone_number',
                    title      : 'Phone Number',
                    titleClass : ''
                },
                {
                    name       : 'company',
                    sortField  : 'company',
                    title      : 'Company',
                    titleClass : ''
                },
                {
                    name       : 'message',
                    sortField  : 'message',
                    title      : 'Message',
                    titleClass : ''
                },
                {
                    name       : '__slot:recive_newsletters',
                    sortField  : 'recive_newsletters',
                    title      : 'Receive Newsletters',
                    titleClass : ''
                }
            ]
        };
    },

    methods : {

        downloadBackup () {
            const that = this;

            that.downloading = true;

            axios.form(urls.backup.contactUs, {}).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.downloading = false;
                    window.open(json.url);
                } else {
                    that.downloading = false;
                    that.$notify('', json.message, {
                        type : 'warning'
                    });
                }
            }).catch(function (exception) {
                that.downloading = false;
                console.log('exception occurred at backup : ', exception);
                that.$notify('Something went wrong..!!', 'Error', {
                    type : 'warning'
                });
            });
        }
    }
};
</script>

<style scoped>

</style>
